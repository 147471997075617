import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getBaseURL } from '../service-config';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private  baseURL: string;

  constructor(private http:HttpClient,) {
    this.baseURL = getBaseURL();
   }

  numberOfRounds(){
    const allRoundsCount = 10;
    return allRoundsCount;
  }

  actualiseData(spielrunde:any): Observable<any> {
    return this.http.get('https://api.planspiel.cephlabs.de/update_personal_values?periode_ext='+spielrunde)
  }

}
