import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storageService/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakOperationService } from 'src/app/services/keycloakService/keycloak.service';
import { ToolService } from 'src/app/services/toolService/tool.service';
import { UserService } from 'src/app/services/userService/user.service';
import { GameService } from 'src/app/services/gameService/game.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css'],
})

export class GameComponent implements OnInit{
  /////steps/////
  step: any = 7;
  subStep: any = 6.1;
  ////input investment var /////
  inputImmo: number = 0;
  inputImmo2: number = 0;
  inputStaat: number = 0;
  inputTag: number = 0;
  ////// daschboard var//////////////////
  gesammtImmo: number =0;
  gesammtStaat: number=0;
  gesammtTag!: number;
  totalReturn: any = 0;
  immobought: any = [];
  staatsanleihen: any;
  week: any;
  gesammtVer: any;
  totalReturnArray:any;
  budget: number = 0;
  ////// player & round //////////////////
  spielrundeForWealthTable=0;
  spielrundeCurrent=0;
  user: any;
  /////timer var//////
  countDownDate: any;
  now: any;
  distance: any;
  timer: any;
  countdown: any;
  ///total return also////
  /// every week by immotest////
  ////////played_round var//////////
  roundPlayed=false; // checks if round played
  ///////////
  rest:number=0;
  //// umfrage var
  renditeChecked: boolean = false;
  zinsumfeldChecked: boolean = false;
  sicherheitChecked: boolean = false;
  spekulationChecked: boolean = false;
  inflationsschutzChecked: boolean = false;
  /////////excel var input /////////
  inflation:number=0;
  inflationText:any=0;
  EK_Zins:number=2;
  EK_ZinsText:number=2;
  Hauspreis1:number=0;
  Hauspreis1Text:number=0;
  Hauspreis2:number=0;
  Hauspreis2Text:number=0;
  jahresrohertrag1:number=0;
  jahresrohertrag2:number=0;
  jahresrohertrag1Text:number=0;
  jahresrohertrag2Text:number=0;
  BWK:number=0;
  BWKText:number=0;
  kalkulationszins:number=0;
  investitionsvolumen:number=0;
  treatmentGroup:any;
  FK_Zins:number=0;
  FK_ZinsText:number=0;
  //////var info
  zinsTG:any=0;
  zinsST:any=0;
  EKZufluss:any=0;
  selectedHaus=1
  roundZero=false;
  totalReturnProzent:any=[];
  ///after keycloack
  userProfile:any;
  numberOfRounds:number=0;

  constructor(
    private storageService: StorageService,
    private keyCloakService:KeycloakOperationService,
    private toolService: ToolService,
    private userService: UserService,
    private gameService: GameService,
    private router: Router) {}

    /**
   * this function is used to  get the round number from db after loading the page
   */
    ngOnInit(): void {

      this.numberOfRounds = this.storageService.numberOfRounds();
      const step = localStorage.getItem('step');
      const timer = localStorage.getItem('timer');

        if (step) this.step=step;
        if(timer){
          const remainingTime = timer;
          const remainingTimeInSeconds = this.parseTimeStringToSeconds(remainingTime);
          this.startRemainingTimer(remainingTimeInSeconds);
        }
        else {
          this.gotoStep(7);
          this.startTimer();
        }
     
      this.gameService.getRoundCurrent().subscribe((res:any)=>{
        this.spielrundeForWealthTable=res.maxPeriodeValue
        this.spielrundeCurrent=this.spielrundeForWealthTable+1
        this.oldlogIn();
      })
    }

  sendCheckedInputs() {
    const selectedInputs = this.survey();
    this.userService.postSurvey(selectedInputs).subscribe(()=>{
      this.keyCloakService.logout();
    })
  }
  
  /**
   * this function is used to set the status of the users to 'played this round' in db
   */
  roundIsPlayed(){
    this.gameService.postPlayedRound(this.user,this.spielrundeCurrent).subscribe((res)=>{
      console.log(res);
    })
  }

  /**
   * this function is used to confirm the input fields and save the investment decisions.
   * first: visual confirmation
   * second: put the decison in a specific format in the var 'eingabe'
   * third: send eingabe to db
   */
  confirmInputInvest() {
    this.visualConfirmation();
    let eingabe = this.decisionTemplate()
    this.gameService.postSaveDecision(eingabe).subscribe((res) => {
      this.roundIsPlayed();
    });
  }


/**
 * this function is used to log in the user
 * first: "actualiseData" request is send to actualise ALL user (this part need to be removed and put elsewhere, it is needed for testing only)
 * second: the tipped email and password are send to db and checked if user exists
 * third:if user exists he will directed to the next pages
 * 4th: if the users exists. user will be checked if he played this week or not 
 * 5th: if user is not found ==> visual feebdack in form of red border to hint out tha email or password are wrong
 */
  oldlogIn() {
    this.keyCloakService.getUserProfile().then((userInfo: any) => {
      this.userProfile = userInfo;
      this.userService.getStud(this.userProfile.email).subscribe((res:any) => {
        if (res && res.success) {       
          this.user = res.data.user;
          this.treatmentGroup=res.data.treatment_group;
          if(!this.treatmentGroup)this.roundZero=true;
          this.gameService.getWealth(this.user, this.spielrundeForWealthTable).subscribe((res:any) => {       
            if (res && res.success) {
              this.budget = parseFloat(res.daten.EK_budget_next_period.toFixed(2));
              this.rest= parseFloat(res.daten.EK_budget_next_period.toFixed(2));
            } 
          });
          this.userService.getZinsen_FK(this.treatmentGroup,this.spielrundeCurrent).subscribe((res:any)=>{
            this.FK_ZinsText=res.daten.Zinsen_FK*100;
            this.FK_Zins=res.daten.Zinsen_FK*100;
          })
          this.toolService.getExcelValues(this.spielrundeCurrent).subscribe((res:any)=>{
            if (res && res.success) {
              this.inflationText=(res.daten.inflation *100).toFixed(3);
              this.Hauspreis1Text=res.daten.immo_kaufpreis1;
              this.Hauspreis2Text=res.daten.immo_kaufpreis2;
              this.jahresrohertrag1Text=res.daten.immo_miete1;
              this.jahresrohertrag2Text=res.daten.immo_miete2;
              this.zinsST=res.daten.Zins_staatsanleihe *100;
              this.zinsTG=res.daten.Zins_tagesgeld *100;
              this.EKZufluss=res.daten.EK_zufluss;
            }
          })
        }
      });
    });
  }

  /**
   * this function is used to check if the user invested all budget or not
   * @returns true or false 
   */
  istGesamtSummeGroesserAlsBudget(): boolean {
    const gesamtSumme = this.inputImmo + this.inputStaat + this.inputTag + this.inputImmo2
    return gesamtSumme == this.budget;
  }

  isRightRange():boolean{
    const value = this.inputImmo;
    return value == 0 || (value >= this.Hauspreis1Text / 2 && value <= this.Hauspreis1Text);
  }

  isRightRange2():boolean{
    const value = this.inputImmo2;
    return  value == 0 || (value >= this.Hauspreis2Text / 2 && value <= this.Hauspreis2Text);
  }

  /**
   * this function calculates how much moneyy is left to invest
   */
  checkRest(){
    const gesamtSumme = (this.inputImmo + this.inputStaat + this.inputTag + this.inputImmo2) as number;
    const x= this.budget-gesamtSumme;
    this.rest = parseFloat((x).toFixed(2));
  }

  putAllMoney(selected:number){
    if (selected == 1) this.inputImmo=this.rest;
    else if (selected == 2) this.inputImmo2=this.rest;
    else if (selected == 3) this.inputStaat=this.rest
    else if (selected == 4) this.inputTag=this.rest;
    setTimeout(() => {this.rest=0;}, 50);
  }

  gotoExcel(){
    this.router.navigate(['/BerechnungsTool']);
  }

  gotoStep(selectedStep: number) {
    localStorage.setItem('step', selectedStep.toString());
    this.step = selectedStep;
  }


/////////////////////////////////////////////////////////////////////templates/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

 
/**
 * this function is used as template to store the investment decisions
 * @returns object
 */
decisionTemplate(){
    return {
      user: this.user,
      periode: this.spielrundeCurrent,// need to verify
      decisions: [
        {
          tagesgeld: this.inputTag,
          staatsanleihen: this.inputStaat,
          immobilie_1: this.inputImmo,
          immobilie_2: this.inputImmo2,
        },
      ],
      game_id:'HAWK'
    };
  }

  /**
   * this function is used as a template to store the investment decisons if timer is finished or player closes or reload the page without making any decision
   * @returns object
   */
  timeIsUpDecisionTemplate(){
    return {
      user: this.user,
      periode: this.spielrundeCurrent,// need to be verified
      decisions: [
        {
          tagesgeld: this.budget,
          staatsanleihen: 0,
          //immobilie:0,
          immobilie_1:0,
          immobilie_2:0,
        },
      ],
      game_id:'HAWK'
    };
  }

  survey(){
    return{
      Rendite: this.renditeChecked,
      Zinsumfeld: this.zinsumfeldChecked,
      Sicherheit:this.sicherheitChecked,
      Spekulation:this.spekulationChecked,
      Inflationsschutz:this.inflationsschutzChecked,
      user:this.user,
      spielrunde:this.spielrundeCurrent
    };
  }
///////////////////////////////////////////////////////////////////////////////////////////////// visual feedback //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////    
/**
 * this function is used to give the user a visual feedback in form of a msg
 * after ths msg is shown the used to directed to the survey
 */
visualConfirmation(){
  let confirmation = document.getElementById('confirmation');
  if(confirmation){
    this.visualFeedback(confirmation);
  }
  setTimeout(() => {
    confirmation!.style.display = 'none';
    this.gotoStep(12);
  }, 1500);
}

 /**
   *  this function is used for visual feedbacks only 
   * @param {div} div centered in the middle of the page with msg 
   */
 visualFeedback(div: any) {
  div!.style.display = 'flex';
  div!.style.justifyContent = 'center';
  div!.style.alignItems = 'center';
  div!.style.position = 'fixed';
  div!.style.height = '100vh';
  div!.style.width = '100vw';
  div!.style.top = '0';
  div!.style.left = '0';
  div!.style.backgroundColor = '#ffffffab';
  div!.style.zIndex = '10';
}

resizeWord(){
  setTimeout(() => {
    const button = document.getElementById('saveBtnResponsive');
    if (button) {
      if (window.innerWidth < 500) button!.innerHTML = 'Speichern';
      else button!.innerHTML = 'Angaben speichern';
    }
  }, 200);
}

/////////////////////////////////////////////////////timer///////////////////////////////////////////////
  /**
   * this function is used to reload the page if the user is not done in 30 min
   * first: visual msg that time is over
   * second: an object that contains the decision is created. all the money is in 'tagesgeld'. the object is saved in var 'eingabe'
   * third: send eingabe to db
   * 4th reload the page
   */
  timeIsUp() {
    let timeIsUp =document.querySelector('.timeIsUp') as HTMLElement;;
    this.visualFeedback(timeIsUp);
    let eingabe = this.timeIsUpDecisionTemplate()
    this.gameService.postSaveDecision(eingabe).subscribe(() => {
      this.roundIsPlayed();
    });

    setTimeout(() => {
      timeIsUp!.style.display = 'none';
    }, 3000);
    setTimeout(() => {
      this.keyCloakService.logout();
    }, 3000);
  }

  /**
 * this function is used to start a 30 min timer
 * if the user in not finished after 30 min the function timeisUp() will be executed
 */
  startTimer() {
    this.countDownDate = new Date();
    this.countDownDate.setMinutes(this.countDownDate.getMinutes() + 30);
    this.countdown = setInterval(() => {
      this.now = new Date().getTime();
      this.distance = this.countDownDate - this.now;

      var minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((this.distance % (1000 * 60)) / 1000);

      this.timer = minutes + 'm ' + seconds + 's ';
      
      localStorage.setItem('timer', this.timer);
      
      if (this.distance < 0) {
        clearInterval(this.countdown);
        this.timer = 'ABGELAUFEN';
        this.timeIsUp();
      }
    }, 1000);
  }

  parseTimeStringToSeconds(timeString: string): number {
    const timeParts = timeString.split(' ');
    let totalSeconds = 0;
  
    timeParts.forEach(part => {
      if (part.includes('m')) {
        totalSeconds += parseInt(part.replace('m', '')) * 60;
      } else if (part.includes('s')) {
        totalSeconds += parseInt(part.replace('s', ''));
      }
    });
  
    return totalSeconds;
  }

  startRemainingTimer(remainingTimeInSeconds: number) {
    this.countDownDate = new Date().getTime() + remainingTimeInSeconds * 1000;
    this.countdown = setInterval(() => {
      this.now = new Date().getTime();
      this.distance = this.countDownDate - this.now;
  
      const minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((this.distance % (1000 * 60)) / 1000);
  
      this.timer = minutes + 'm ' + seconds + 's ';

      localStorage.setItem('timer', this.timer);

      if (this.distance < 0) {
        clearInterval(this.countdown);
        this.timer = 'ABGELAUFEN';
        this.timeIsUp();
      }
    }, 1000);
  }

}