import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getBaseURL } from '../service-config';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private  baseURL: string;
  private specificURL:string;

  constructor(private http:HttpClient,) {
    this.baseURL = getBaseURL();
    this.specificURL = 'user'
   }

   getStud(loginEmail:any){
    const body = { LoginEmail: loginEmail};
    return this.http.post(`${this.baseURL}/${this.specificURL}/getStudent`, body);
  }

  postSurvey(umfrage:any){
    return this.http.post(`${this.baseURL}/${this.specificURL}/postSurvey`,umfrage)
  }

  getZinsen_FK(treatmentGroup:any,runde:any){
    return this.http.get(`${this.baseURL}/${this.specificURL}/getZinsen_FK?treatmentGroup=${treatmentGroup}&Runde=${runde}`);
  }

  isUserRegistred(data: any): Observable<any> {
      return this.http.post<any>(`${this.baseURL}/${this.specificURL}/isUserRegistred`, data);
  }

}
