import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameService } from 'src/app/services/gameService/game.service';
import { KeycloakOperationService } from 'src/app/services/keycloakService/keycloak.service';
import { StorageService } from 'src/app/services/storageService/storage.service';
import { ToolService } from 'src/app/services/toolService/tool.service';
import { UserService } from 'src/app/services/userService/user.service';

@Component({
  selector: 'app-excel-tool',
  templateUrl: './excel-tool.component.html',
  styleUrls: ['./excel-tool.component.css']
})
export class ExcelToolComponent implements OnInit {

  budget: number = 0;
  ////// player & round //////////////////
  spielrundeForWealthTable=0;
  spielrundeCurrent=0;
  user: any;
  /////timer var//////
  countDownDate: any;
  now: any;
  distance: any;
  timer: any;
  countdown: any;
  /////////excel var input /////////
  inflation:number=0;
  inflationText:any=0;
  EK_Zins:number=2;
  EK_ZinsText:number=2;
  Hauspreis1:number=0;
  Hauspreis1Text:number=0;
  Hauspreis2:number=0;
  Hauspreis2Text:number=0;
  jahresrohertrag1:number=0;
  jahresrohertrag2:number=0;
  jahresrohertrag1Text:number=0;
  jahresrohertrag2Text:number=0;
  BWK:number=0;
  BWKText:number=0;
  kalkulationszins:number=0;
  investitionsvolumen:number=0;
  treatmentGroup:any;
  FK_Zins:any=0;
  FK_ZinsText:number=0;
///after Keycloack
  numberOfRounds:number=0;
  numberOfRoundsText:number=0;
  //////////////////excel var output
  verbRounds:number=0;
  FK_Volumen:number=0;
  LTV:number=0;
  reinertrag:number=0;
  nettoanfangsrendite:number=0;
  FK_Zinskosten:number=0;
  kalkulationszinsKapitalwert:number=0;
  internerZinsfussPrognostiziert:number=0;
  kapitalwertPrognostiziert:number=0;
  //////var info
  zinsTG:any=0;
  zinsST:any=0;
  EKZufluss:any=0;
  selectedHaus:number=1

  totalReturnProzent:any=[];
  ///after keycloack
  userProfile:any

  constructor(
    private storageService: StorageService,
    private toolService: ToolService,
    private keyCloakService:KeycloakOperationService,
    private router: Router,
    private userService: UserService,
    private gameService: GameService,
     ) {}

  ngOnInit(){
    this.numberOfRounds = this.storageService.numberOfRounds();
    this.numberOfRoundsText = this.storageService.numberOfRounds();

    const timer = localStorage.getItem('timer');
    const remainingTime =timer
    const remainingTimeInSeconds = this.parseTimeStringToSeconds(remainingTime);
    this.startRemainingTimer(remainingTimeInSeconds);
   
 
  this.gameService.getRoundCurrent().subscribe((res:any)=>{
    this.spielrundeForWealthTable=res.maxPeriodeValue
    this.spielrundeCurrent=this.spielrundeForWealthTable+1
    this.keyCloakService.getUserProfile().then((userInfo: any) => {
      this.userProfile = userInfo;
      this.userService.getStud(this.userProfile.email).subscribe((res:any) => {
        if (res && res.success) {       
          this.user = res.data.user;
          this.treatmentGroup=res.data.treatment_group;
          this.gameService.getWealth(this.user, this.spielrundeForWealthTable).subscribe((res:any) => {      
            if (res && res.success) {
              this.budget = parseFloat(res.daten.EK_budget_next_period.toFixed(2));
              this.excel();
            } 
          });
        }
      });
    });
  })
  }

  excel(){
    this.userService.getZinsen_FK(this.treatmentGroup,this.spielrundeCurrent).subscribe((res:any)=>{
      this.FK_ZinsText=res.daten.Zinsen_FK*100;
      this.FK_Zins=(res.daten.Zinsen_FK*100).toFixed(2) ;
    this.toolService.getExcelValues(this.spielrundeCurrent).subscribe((res:any)=>{
      if (res && res.success) {      
        this.inflationText=(res.daten.inflation *100).toFixed(3);
        this.Hauspreis1Text= res.daten.immo_kaufpreis1;
        this.Hauspreis2Text= res.daten.immo_kaufpreis2;
        this.jahresrohertrag1Text= res.daten.immo_miete1;
        this.jahresrohertrag2Text= res.daten.immo_miete2;
        this.BWKText=res.daten.BWK *100;
        this.inflation=Number((res.daten.inflation *100).toFixed(3));
        this.Hauspreis1= res.daten.immo_kaufpreis1;
        this.Hauspreis2= res.daten.immo_kaufpreis2;
        this.jahresrohertrag1= res.daten.immo_miete1;
        this.jahresrohertrag2= res.daten.immo_miete2;
        this.BWK=res.daten.BWK *100;

        const calc={
          runde:this.spielrundeCurrent,
          restRounds:this.numberOfRounds-this.spielrundeCurrent,
          inflation: this.inflationText/100,
          FK_Zins:this.FK_ZinsText/100,
          EK_Zins:this.EK_Zins/100,
          BWK:this.BWKText/100,
          Hauspreis:this.Hauspreis1Text,
          jahresrohertrag:this.jahresrohertrag1Text,
          investitionsvolumen:this.investitionsvolumen,
          kalkulationszins:this.kalkulationszins/100
        }
    
        this.toolService.postExcelValues(calc).subscribe((res:any)=>{
          this.verbRounds=this.numberOfRounds-this.spielrundeCurrent
          this.FK_Volumen=res.FK_Volumen*1
          this.LTV=res.LTV*100
          this.reinertrag=res.Reinertrag*1
          this.nettoanfangsrendite=res.Nettoanfangsrendite*100
          this.FK_Zinskosten=res.FK_Zinskosten*1
          this.kalkulationszinsKapitalwert=res.Kalkulationszins_Kapitalwert*100
          this.internerZinsfussPrognostiziert=res.Interner_Zinsfuss_prognostiziert*100
          this.kapitalwertPrognostiziert=res.Kapitalwert_prognostiziert*1
        })
      }
    })
  })
  }

  calcExcel(){
    const calc={
      runde:this.spielrundeCurrent,
      restRounds:this.numberOfRounds-this.spielrundeCurrent,
      inflation:this.inflation/100,
      FK_Zins:this.FK_Zins/100,
      EK_Zins:this.EK_Zins/100,
      BWK:this.BWK/100,
      Hauspreis:this.selectedHaus==1? this.Hauspreis1: this.Hauspreis2,
      jahresrohertrag: this.selectedHaus==1 ? this.jahresrohertrag1:this.jahresrohertrag2,
      investitionsvolumen:this.investitionsvolumen,
      kalkulationszins:this.kalkulationszins/100
    }

    this.toolService.postExcelValues(calc).subscribe((res:any)=>{
      this.verbRounds=this.numberOfRounds-this.spielrundeCurrent
      this.FK_Volumen=res.FK_Volumen*1
      this.LTV=res.LTV*100
      this.reinertrag=res.Reinertrag*1
      this.nettoanfangsrendite=res.Nettoanfangsrendite*100
      this.FK_Zinskosten=res.FK_Zinskosten*1
      this.kalkulationszinsKapitalwert=res.Kalkulationszins_Kapitalwert*100
      this.internerZinsfussPrognostiziert=res.Interner_Zinsfuss_prognostiziert*100
      this.kapitalwertPrognostiziert=res.Kapitalwert_prognostiziert*1
    })
  }

  gotoGameStep11() {
    localStorage.setItem('step', '11');
    this.router.navigate(['/game']);
  }
  gotoGameStep9() {
    localStorage.setItem('step', '9');
    this.router.navigate(['/game']);
  }

  roundIsPlayed(){
    this.gameService.postPlayedRound(this.user,this.spielrundeCurrent).subscribe((res)=>{
      console.log(res);
    })
  }

  ///////////////////////////////////////////////////////////////timer section///////////////////////////////////////////////////

  parseTimeStringToSeconds(timeString: any): number {
    const timeParts = timeString.split(' ');
    let totalSeconds = 0;
  
    timeParts.forEach((part:any) => {
      if (part.includes('m')) {
        totalSeconds += parseInt(part.replace('m', '')) * 60;
      } else if (part.includes('s')) {
        totalSeconds += parseInt(part.replace('s', ''));
      }
    });
  
    return totalSeconds;
  }

  startRemainingTimer(remainingTimeInSeconds: number) {
    this.countDownDate = new Date().getTime() + remainingTimeInSeconds * 1000;
    this.countdown = setInterval(() => {
      this.now = new Date().getTime();
      this.distance = this.countDownDate - this.now;
  
      const minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((this.distance % (1000 * 60)) / 1000);
  
      this.timer = minutes + 'm ' + seconds + 's ';
      if (this.distance < 0) {
        clearInterval(this.countdown);
        this.timer = 'ABGELAUFEN';
        this.timeIsUp();
      }
    }, 1000);
  }



  timeIsUp() {
    let timeIsUp =document.querySelector('.timeIsUp') as HTMLElement;;
    this.visualFeedback(timeIsUp);
    let eingabe = this.timeIsUpDecisionTemplate()
    this.gameService.postSaveDecision(eingabe).subscribe(() => {
      this.roundIsPlayed();
    });
    setTimeout(() => {
      timeIsUp!.style.display = 'none';
    }, 3000);
    setTimeout(() => {
      this.keyCloakService.logout();
    }, 3000);
  }


    /**
   * this function is used as a template to store the investment decisons if timer is finished or player closes or reload the page without making any decision
   * @returns object
   */
    timeIsUpDecisionTemplate(){
      return {
        user: this.user,
        periode: this.spielrundeCurrent,// need to be verified
        decisions: [
          {
            tagesgeld: this.budget,
            staatsanleihen: 0,
            immobilie_1:0,
            immobilie_2:0,
          },
        ],
        game_id:'HAWK'
      };
    }

   /**
   *  this function is used for visual feedbacks only 
   * @param {div} div centered in the middle of the page with msg 
   */
 visualFeedback(div: any) {
  div!.style.display = 'flex';
  div!.style.justifyContent = 'center';
  div!.style.alignItems = 'center';
  div!.style.position = 'fixed';
  div!.style.height = '100vh';
  div!.style.width = '100vw';
  div!.style.top = '0';
  div!.style.left = '0';
  div!.style.backgroundColor = '#ffffffab';
  div!.style.zIndex = '10';
}

  resizeWord(){
    setTimeout(() => {
      const button = document.getElementById('saveBtnResponsive');
      if (button) {
        if (window.innerWidth < 500) button!.innerHTML = 'Speichern';
        else button!.innerHTML = 'Angaben speichern';
      }
    }, 200);
  }

}
