import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getBaseURL } from '../service-config';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  private  baseURL: string;
  private  specificURL:string;

  constructor(private http:HttpClient,) {
    this.baseURL = getBaseURL();
    this.specificURL = 'game'
   }

  postSaveDecision(inputEingabe:any){
    return this.http.post(`${this.baseURL}/${this.specificURL}/saveDecision`,inputEingabe)
  }

  getWealth(user:any, runde:any){
    const body = { User: user, Runde: runde };
    return this.http.post(`${this.baseURL}/${this.specificURL}/getWealth`, body );
  }

  getChartData(user:any,runde:any){
    const body = { User: user, Runde: runde };
    return this.http.post(`${this.baseURL}/${this.specificURL}/getChartData`, body );
  }

  postPlayedRound(user:any,runde:any){
    const body = { User: user, Runde: runde };
    return this.http.post(`${this.baseURL}/${this.specificURL}/postRoundPlayed`, body );
  }

  getPlayedStatus(user:any,runde:any){
    return this.http.get(`${this.baseURL}/${this.specificURL}/getRoundStatus?User=${user}&Runde=${runde}`);
  }

  getRoundCurrent(){
    return this.http.get(`${this.baseURL}/${this.specificURL}/currentPeriodeValue`)
  }
}
