import { Component } from '@angular/core';
import { KeycloakOperationService } from './services/keycloakService/keycloak.service';
import { UserService } from './services/userService/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Real_Estate_Game';
  users:any;
  userProfile:any

  constructor(
    private keyCloakService: KeycloakOperationService,
    private userService: UserService,
)
{}

  ngOnInit(): void {
    this.keyCloakService.getUserProfile().then((userInfo: any) => {
      this.userProfile = userInfo;
      const data = { hawkEmail: this.userProfile.email };
      this.userService.isUserRegistred(data).subscribe((res) => {
        
      }
      );
    });
  }
}
