import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GameComponent } from './components/game/game.component';
import { AuthGuardService } from './guard/auth-guard.service';
import { StartComponent } from './components/start/start.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ExcelToolComponent } from './components/excel-tool/excel-tool.component';

export const routes: Routes = [
  { path: '', component: StartComponent ,canActivate: [AuthGuardService]},
  { path: 'dashboard', component: DashboardComponent ,canActivate: [AuthGuardService]},
  { path: 'game', component: GameComponent ,canActivate: [AuthGuardService]},
  { path: 'BerechnungsTool', component: ExcelToolComponent ,canActivate: [AuthGuardService]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
