import { APP_INITIALIZER,NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GameComponent } from './components/game/game.component';

import { FormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import { KeycloakAngularModule, KeycloakService,KeycloakBearerInterceptor  } from 'keycloak-angular';
import { initializeKeycloak} from './init/keycloak-init.factory';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { routes } from './app-routing.module';
import { provideClientHydration } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { StartComponent } from './components/start/start.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ExcelToolComponent } from './components/excel-tool/excel-tool.component';


@NgModule({
  declarations: [
    AppComponent,
    GameComponent,
    StartComponent,
    DashboardComponent,
    ExcelToolComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
  
  ],
  providers: [ 
    HttpClient,
    provideRouter(routes),
    provideClientHydration(),
    //provideAnimationsAsync(),
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true
    },
    provideHttpClient(
      withInterceptorsFromDi() // tell httpClient to use interceptors from DI
    ),],
  bootstrap: [AppComponent]
})
export class AppModule { }
