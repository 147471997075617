import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'chart.js/auto';
import { GameService } from 'src/app/services/gameService/game.service';
import { KeycloakOperationService } from 'src/app/services/keycloakService/keycloak.service';
import { ToolService } from 'src/app/services/toolService/tool.service';
import { UserService } from 'src/app/services/userService/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent {
  /////steps/////
  subStep: any = 6.1;
  ////// daschboard var//////////////////
  gesammtImmo: number = 0;
  gesammtStaat: number = 0;
  gesammtTag!: number;
  totalReturn: any = 0;
  immobought: any = [];
  staatsanleihen: any;
  week: any;
  gesammtVer: any;
  totalReturnArray: any;
  budget: number = 0;
  ////// player & round //////////////////
  spielrundeForWealthTable = 0;
  spielrundeCurrent = 0;
  user: any;
  //////chart var///////
  chart: any;
  chartTotalReturn: any;
  chartCounter = 1;
  ////////played_round var//////////
  roundPlayed = false; // checks if round played
  /// need to check waht for///
  rest: number = 0;
  roundZero = false;
  endRound =false;
  treatmentGroup: any;
  inflationText: any = 0;
  FK_ZinsText: number = 0;
  //dokno
  totalReturnProzent: any = [];
  ///after keycloack
  userProfile: any;

  constructor(
    private keyCloakService: KeycloakOperationService,
    private toolService: ToolService,
    private userService: UserService,
    private gameService: GameService,
    private router: Router
  ) {}

  /**
   * this function is used to  get the round number from db after loading the page
   */
  ngOnInit(): void {
    localStorage.removeItem('timer');
    localStorage.removeItem('step');

    this.gameService.getRoundCurrent().subscribe((res: any) => {
      if (!res.success) {
        this.spielrundeForWealthTable = 0;
        this.spielrundeCurrent = 0;
        this.roundZero = true;
      } else {
        this.spielrundeForWealthTable = res.maxPeriodeValue;
        console.log(res);

        this.spielrundeCurrent = this.spielrundeForWealthTable + 1;
        this.oldlogIn();
        if (res.maxPeriodeValue == 10) this.endRound = true
      }
    });
  }

  oldlogIn() {
    this.keyCloakService.getUserProfile().then((userInfo: any) => {
      this.userProfile = userInfo;
      this.userService.getStud(this.userProfile.email).subscribe((res: any) => {
        if (res && res.success) {
          this.user = res.data.user;
          this.treatmentGroup = res.data.treatment_group;
          this.getKennzahlen();
          this.loadGesammt();
          this.checkIfUserPlayedRound();
        }
      });
    });
  }

  getKennzahlen() {
    if (!this.endRound) {
      this.userService.getZinsen_FK(this.treatmentGroup, this.spielrundeCurrent).subscribe((res: any) => {      
        this.FK_ZinsText = res.daten.Zinsen_FK * 100;
      });
    }
    this.toolService.getExcelValues(this.spielrundeCurrent).subscribe((res: any) => {
        this.inflationText = (res.daten.inflation * 100).toFixed(3);
      });
  }

  startGame() {
    this.router.navigateByUrl('/game');
  }

  /**
   * this function is used to check if user played this round this week or not
   * first: user name and round number are send to db. the response is a bool var (true: played round this week/false: did not play this week)
   * second the response is stored in the bool var roundPlayed
   */
  checkIfUserPlayedRound() {
    this.gameService.getPlayedStatus(this.user, this.spielrundeCurrent).subscribe((result: any) => {
        if (result && result.success) this.roundPlayed = result.daten.roundPlayed;
      });
  }

  /**
   * this function is used to load all dashboard infos
   * first: the 'immobilien','staatsanleihe','budget'...
   * second: only the chart infos
   */
  loadGesammt() {
    this.getDaschboardData();
    this.getChartData();
  }

  /**
   * this function is used to load from db the daschboard infos except the chart
   * after recieving the infos. the values are rendered using the function 'loadDashboardInd'
   */
  getDaschboardData() {
    this.gameService
      .getWealth(this.user, this.spielrundeForWealthTable).subscribe((res: any) => {
        if (res && res.success) this.loadDashboardInd(res);
      });
  }

  /**
   * this function is used to load the chart data from db
   * first: a reguest to get the datat is send
   * second: the data are put in the right format using 'loadChartData'
   * third: the Line chart is created using chat.js
   */
  getChartData() {
    setTimeout(() => {
      this.gameService.getChartData(this.user, this.spielrundeForWealthTable).subscribe((res: any) => {
          if (res && res.success) this.loadChartData(res);
          if (this.spielrundeForWealthTable > 1) this.createChart();
        });
    }, 100);
  }

  /**
   * this function is used to render the values for the dashboard
   * @param { Json} res containes info about the user
   */
  loadDashboardInd(res: any) {
    this.gesammtImmo = parseFloat(res.daten.immobilie.toFixed(2));
    this.gesammtStaat = parseFloat(res.daten.staatsanleihen_total.toFixed(2));
    this.budget = parseFloat(res.daten.EK_budget_next_period.toFixed(2));
    this.rest = parseFloat(res.daten.EK_budget_next_period.toFixed(2));
    this.totalReturn = (res.daten.total_return * 100).toFixed(2);
    this.immobought = res.daten.immos_bought;
    this.staatsanleihen = res.daten.staatsanleihen.filter((staat: any) => staat.staatsanleihe != 0);
  }

  /**
   * this function is used to mput the data from db in the right format for creating the line chart
   * x-axis are the weks and y-axis are the total portfolio value
   * @param { Json} res containes info about the user
   */
  loadChartData(res: any) {
    this.week = res.periode.slice(1);
    this.gesammtVer = res.totalPotfolioValue.slice(1);
    this.totalReturnArray = res.totalReturn.slice(1);
    this.totalReturnArray.forEach((element: number) => {
      element = element * 100;
      this.totalReturnProzent.push(element);
    });
  }

  logout(){
    this.keyCloakService.logout();
  }

  /////////////////////////////////////////////////      Chart Section   /////////////////////////////////////////////////////////////////////
  /**
   * Updates the chart based on the current chart counter.
   * If a chart exists, it is destroyed before creating a new one.
   * Uses a timeout to ensure proper chart destruction before recreation.
   * @param {number} chartCounter - Indicates which chart to update (1 for 'chart', 2 for 'chartTotalReturn').
   */
  updateChart(chartCounter: number): void {
    this.chartCounter = chartCounter;
    if (this.chart) this.chart.destroy();
    if (this.chartTotalReturn) this.chartTotalReturn.destroy();

    setTimeout(() => {
      if (chartCounter === 1) this.createChart();
      else if (chartCounter === 2) this.createChartTotalReturn();
    }, 200);
  }

  /**
   * Creates the 'Wertentwicklung' chart.
   */
  createChart(): void {
    this.chart = new Chart('chart', {
      type: 'line',
      data: {
        labels: this.week,
        datasets: [
          {
            data: this.gesammtVer,
            borderColor: 'cornflowerblue',
            label: 'Wertentwicklung',
          },
        ],
      },
      options: this.getChartOptions('Gesamtvermögen €', 'Wertentwicklung'),
    });
  }

  /**
   * Creates the 'Total Return' chart.
   */
  createChartTotalReturn(): void {
    const shadowPlugin = {
      id: 'barShadow',
      beforeDraw: (chart: any) => {
        const ctx = chart.ctx;
        chart.data.datasets.forEach((dataset: any, i: number) => {
          const meta = chart.getDatasetMeta(i);
          meta.data.forEach((bar: any) => {
            ctx.save();  // Save the state for each bar
            ctx.shadowColor = 'rgba(0, 0, 0, 0.4)';
            ctx.shadowBlur = 15;
            ctx.shadowOffsetX = 5;
            ctx.shadowOffsetY = 0;
  
            ctx.fillRect(
              bar.x - bar.width / 2,  // Position X
              bar.y,                  // Position Y
              bar.width,              // Bar width
              bar.base - bar.y        // Bar height
            );
            ctx.restore();  // Restore the state after drawing each bar
          });
        });
      }
    };
  
    this.chartTotalReturn = new Chart('chartTotalReturn', {
      type: 'bar',
      data: {
        labels: this.week,
        datasets: [
          {
            data: this.totalReturnProzent,
            borderColor: '#aa74c9',
            borderWidth: 1,
            backgroundColor: 'deepskyblue',
            label: 'Total Return',
          },
        ],
      },
      options: this.getChartOptions('total return %', 'Total return'),
      plugins: [shadowPlugin],
    });
  }
  

  /**
   * Returns the common chart options object.
   * @param {string} yAxisLabel - The label for the Y-axis.
   * @param {string} titleText - The title text for the chart.
   * @returns {Object} - Chart options object.
   */
  private getChartOptions(yAxisLabel: string, titleText: string): any {
    return {
      scales: {
        x: {
          title: {
            display: true,
            text: 'Jahre',
            color: 'black',
          },
          grid: {
            display: false,
          },
          ticks: {
            color: 'black',
          },
        },
        y: {
          title: {
            display: true,
            text: yAxisLabel,
            color: 'black',
          },
          grid: {
            display: false,
          },
          ticks: {
            color: 'black', 
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: titleText,
          color: 'black',
          font: {
            size: 16,
          },
        },
      },
    };
  }
}
