import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getBaseURL } from '../service-config';

@Injectable({
  providedIn: 'root'
})
export class ToolService {

  private  baseURL: string;
  private specificURL:string

  constructor(private http:HttpClient,) {
    this.baseURL = getBaseURL();
    this.specificURL='tool';
   }

   getExcelValues(runde:any){
    return this.http.get(`${this.baseURL}/${this.specificURL}/getExcelValues?Runde=`+runde);
  }

  postExcelValues(body:any){
    return this.http.post('https://api.planspiel.cephlabs.de/excel_tool',body)
  }

}
