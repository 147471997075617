import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class KeycloakOperationService {


  private baseUrl = 'https://keycloak.cephlabs.de/admin/realms/RealEstateGame';

  constructor(
    private readonly keycloak: KeycloakService,
    private http: HttpClient
  ) {}

  isLoggedIn(): any {
    return this.keycloak.isLoggedIn();
  }

  logout(): void {
    this.keycloak.logout();
  }

  getUserProfile(): any {
    return this.keycloak.loadUserProfile();
  }

  getUsersList(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/users`, {
      headers: {
        Authorization: `Bearer ${this.keycloak.getToken()}`
      }
    })
  }

}
