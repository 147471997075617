import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakOperationService } from 'src/app/services/keycloakService/keycloak.service';
import { StorageService } from 'src/app/services/storageService/storage.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit{
  step=1;
  numberOfRounds:number = 0;

  constructor(
    private router:Router,
    private storageService: StorageService,
    private keycloakService:KeycloakOperationService
  )
  {}

  ngOnInit(): void {
    this.numberOfRounds = this.storageService.numberOfRounds();
    localStorage.removeItem('timer');
    localStorage.removeItem('step');
  }

  goToDashboard(){
    this.router.navigateByUrl('/dashboard')
  }

  logout(){
    this.keycloakService.logout();
  }
  
}
