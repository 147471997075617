
export const AppConfig = {
    useLocal: false,
    baseURL: 'http://localhost:3000/realEstate',
    remoteURL: 'https://realestateservice.cephlabs.de/realEstate'
  };
  
  export const getBaseURL = () => {
    return AppConfig.useLocal ? AppConfig.baseURL : AppConfig.remoteURL;
  };
  
