<!-- ########################################################### main Page : wilkommen Seite ################################################################################################################################################################################################   -->
<button class="btn btn-danger logout" (click)="logout()"><i class="bi bi-box-arrow-left"></i> Logout</button>
<div *ngIf="step == 1" id="WillkommenContainer">
  
  <h1>Herzlich Willkommen!</h1>

  <div class="white-box">
    <div class="textInformation">
      Wir sind Studierende des 3. Master-Semesters Immobilienmanagement und benötigen Eure Hilfe!
      <br>
      Über einen Zeitraum von {{numberOfRounds}} Wochen werden Euch verschiedene Szenarien präsentiert, bei denen ihr Euch mit Eurer Expertise als Young Professionals für eine Anlagestrategie entscheidet. 
      <br>
      Dafür braucht ihr nicht mehr als 30 Minuten pro Woche und bekommt, je nach Teilnahmezahl Eures Semesters, bis zu 5% der Klausurpunkte geschenkt. Eine aktive Teilnahme an jeder Periode führt in den meisten Fällen zu einem besseren Ergebnis, weil als Default-Entscheidung euer sämtliches Kapital auf einem niedrig verzinsten Tagesgeldkonto angelegt wird.
      <br>
      Bitte tragt die erforderlichen Daten auf der nächsten Seite ein. Unmittelbar nach der Registrierung könnt ihr euch zwar schon einloggen, aber die angezeigten Daten entsprechen erst den tatsächlichen Zahlen, wenn die erste Periode des Spiels gestartet wurde.
    </div>

    <div class="btn-area">
      
      <button class="btn btn-primary" (click)="step = 2">
        Weiter
      </button>
    </div>
  </div>
  
 

  
  </div>
  
  
  <!-- ########################################################### Spielanleitung #########################################################################################################################################################################################################   -->
  
  
  <div *ngIf="step == 2" class="ngIfPlace">
    <h1>Spielanleitung</h1>
  
    <div class="white-box">
      <div class="textInformationSpielanleitung">
        <div>
          • Das Spiel erstreckt sich über {{numberOfRounds}} Runden in einem Zeitraum von {{numberOfRounds}} Wochen. Im zeitlichen Horizont entspricht 1 Woche fiktiv 1 Jahr im Spiel.
        </div>
        <div>
          • Die Spieler haben jede Woche 30 Minuten Zeit zur Verfügung, die Aufgabenstellung wird ohne zeitliche Beschränkung angezeigt.
        </div>
        <div>
          • Jeder Spieler bekommt in der 1. Periode ein Startkapital in Höhe von 10 Mio.€, und jede weitere Periode zusätzliches Kapital von 5 Mio. €, welches jede Woche mit einer Anlagestrategie komplett in die Investitionsmöglichkeiten angelegt werden soll.
        </div>
        <div>
          • Ziel des Spiels ist, durch strategisches Handeln einen nachhaltigen Aufbau des Portfolios zu erreichen, indem in Immobilien aus dem Schwerpunkt ‚Wohnen‘ oder Alternativanlagen investiert wird.
        </div>
        <div>
          • Für die Auswertung werden zum Ende der {{numberOfRounds}}. Woche sämtliche Werte der Anlagen addiert, und der Wert der Schulden abgezogen. Die über den Zeitraum erwirtschafteten Einnahmen & Renditen werden während des Spiel bereits ausgezahlt und können reinvestiert werden, wohingegen die zu zahlenden Zinsen für aufgenommene Bankkredite bereits während des Spiel mit den Einnahmen verrechnet werden.
        </div>
        <div>
          • Gewonnen hat das Semester mit der besten Performance (relativ in %; Endbestand im Verhältnis zum Anfangsbestand).
        </div>
        <div>
          • Falls die spielende Person es nicht schafft, in der vorgegebenen Zeit das Geld zu investieren, wird das Geld automatisch in Tagesgeldkonten angelegt. Das Spiel ist damit für die Woche beendet.
        </div>
      </div>
      <div class="btn-area">
        <button class="btn btn-primary" (click)="step = 3">
          Weiter
        </button>
      </div>
    </div>
  </div>
  
  
  
  <!-- ########################################################### Ausgangslage #########################################################################################################################################################################################################   -->
  
  <div *ngIf="step == 3" class="ngIfPlace">
    <h1>Ausgangslage</h1>
  
        <div class="white-box">
          <div class="textAusgang">
            Sie sind Expansionsmanager bei der HAWK-Investment mit Sitz in Hamburg. Als institutioneller Investor konzentrieren Sie sich auf die Anlage in Wohnimmobilien, im Speziellen in Mehrfamilienhäusern. 
            Um Ihren Anlegern die bestmögliche Rendite auf ihre Einlage zu gewährleisten, ist es Ihre Aufgabe, ein neues Portfolio in der HAWK-Investment über den Betrachtungszeitraum von {{numberOfRounds}} Jahren über die kommenden {{numberOfRounds}} Wochen zu etablieren und mit Blick auf den Endwert zu optimieren. 
            Hierzu haben Sie in jeder Woche die Möglichkeit, das zur Verfügung gestellte Kapital beliebig auf verschiedene Anlageformen aufzuteilen: Sie können in bis zu zwei angebotene Immobilien investieren, außerdem ein beliebiges Volumen an Staatsanleihen zu kaufen und schließlich noch das übrige Kapital zum jeweiligen Tageszins zu halten.
            Sie sind bestrebt, einen möglichst wirtschaftlichen Verschuldungsgrad zu erreichen, jedoch soll Fremdkapital mit einem maximalen LTV von 50% moderat eingesetzt werden. Die Finanzierungen sollen auf Objektebene betrachtet werden. 
            
            <div class="btn-area-two">
              <button class="btn btn-primary" (click)="step = 2"> Zurück</button>
              <button class="btn btn-primary" (click)="goToDashboard();">Weiter</button>
            </div>
      
          </div>
        </div>

  </div>